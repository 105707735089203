import './public-path';
import main from 'goals/main';

import { start } from '../goals/common';
import { loadLocale } from '../goals/locales';
import { loadPolyfills } from '../goals/polyfills';

start();

loadPolyfills()
  .then(loadLocale)
  .then(main)
  .catch((e: unknown) => {
    console.error(e);
  });
